<template>
  <div class="warranty-list">
    <!-- 我的延保 -->
    <!-- 顶栏-START -->
    <van-sticky>
      <div class="warranty-user">
        合作商：<span>{{ $store.getters.name }}</span>
      </div>
    </van-sticky>
    <!-- 顶栏-END -->

    <!-- 我的延保列表-START -->
    <div class="my-warranty-list">
      <van-pull-refresh v-model="pageData.refreshing" @refresh="onRefresh">
        <div class="warranty-list-title">我的延保列表</div>
        <van-list
          class="list-info"
          v-model:loading="pageData.loading"
          v-model:error="pageData.error"
          :finished="pageData.finished"
          error-text="请求失败，点击重新加载"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div v-if="pageData.list.length > 0">
            <van-row v-for="(item, index) in pageData.list" :key="index">
              <van-col span="8" class="list-info-date">
                <div class="date-title">申请日期</div>
                <div class="date-info">{{ item.time }}</div>
              </van-col>
              <van-col span="8" class="list-info-mac text-algin-center">
                <div class="mac-num">{{ item.num }}台</div>
                <div class="mac-info" @click="showMac(item)">查看</div>
              </van-col>
              <van-col span="8" class="list-info-status text-algin-center">
                <div class="status-info" v-if="item.status == 3">拒绝</div>
                <div
                  class="status-info color-1FBF21"
                  v-else-if="item.status == 2"
                >
                  通过
                </div>
                <div class="status-info color-FF4949" v-else>审批中</div>
                <div
                  class="status-reason"
                  v-if="item.status == 3"
                  @click="showReason(item)"
                >
                  查看原因
                </div>
              </van-col>
            </van-row>
          </div>
          <van-empty v-else description="暂无数据" />
        </van-list>
      </van-pull-refresh>
    </div>
    <!-- 我的延保列表-END -->

    <!-- 延保终端弹窗-START -->
    <van-dialog
      use-slot
      title="申请延保mac列表"
      class="show-mac"
      :show="pageData.showMac"
      :show-confirm-button="false"
    >
      <span class="close-show-mac" @click="pageData.showMac = false"
        ><van-icon name="cross"
      /></span>
      <div class="auth-mac">
        <van-row>
          <van-col span="12">终端型号</van-col>
          <van-col span="12">MAC</van-col>
        </van-row>
        <van-row v-for="(item, index) in pageData.authMac" :key="index">
          <van-col span="12">{{ item.device_type }}</van-col>
          <van-col span="12">{{ item.mac }}</van-col>
        </van-row>
      </div>
    </van-dialog>
    <!-- 延保终端弹窗-END -->

    <!-- 审核拒绝理由弹窗-START -->
    <van-dialog
      use-slot
      class="show-reason"
      :show="pageData.showReason"
      theme="round-button"
      @confirm="pageData.showReason = false"
    >
      <van-cell-group :border="false">
        <van-cell
          title="拒绝原因："
          :value="pageData.authTime"
          :border="false"
        />
      </van-cell-group>
      <div style="padding: 0 12px">
        <van-field
          v-model="pageData.authReason"
          class="reject-reason"
          type="textarea"
          rows="4"
          :readonly="true"
        />
      </div>
    </van-dialog>
    <!-- 审核拒绝理由弹窗-END -->
  </div>
</template>

<script setup>
import { reactive } from "vue";
import store from "@/store/index.js";

import { warrantyList } from "@/api/warranty";
import { Toast } from "vant";

/* VUE插件注册-START */
/* VUE插件注册-END */

/* 变量初始化-START */
const pageData = reactive({
  list: [] /* 我的延保列表 */,
  refreshing: false /* 下拉刷新 */,
  loading: false /* 加载状态 */,
  error: false /* 错误提示 */,
  finished: false /* 数据加载完成 */,
  page: 1 /* 列表页数 */,
  limit: 10 /* 列表单页查询条数 */,
  total: 0,
  showMac: false /* 延保终端弹窗 */,
  showReason: false /* 审核拒绝理由弹窗 */,
  authTime: "" /* 审核时间 */,
  authReason: "" /* 审核理由 */,
  authMac: [] /* 审核终端 */,
});
const user_id = store.getters.userid;
/* 变量初始化-END */

/* 函数初始化-START */
/* 延保数据列表查询 */
const onLoad = () => {
  pageData.refreshing = false;
  if (!user_id) {
    pageData.loading = false;
    return false;
  }
  if (pageData.list.length > 0 && pageData.list.length >= pageData.total) {
    return false;
  }
  const loadServer = warrantyList({
    page: pageData.page,
    limit: pageData.limit,
    user_id: user_id,
  });
  loadServer
    .then((result) => {
      pageData.loading = false;
      if (result.data && result.data.code && +result.data.code === 200) {
        result.data.data.forEach((element) => {
          element.time = element.created_at.split(" ")[0];
          element.num = element.mac.split(",").length;
          pageData.list.push(element);
        });
        pageData.page += 1;
        pageData.total = result.data.total;
        if (pageData.list.length >= result.data.total) {
          pageData.finished = true;
        }
      } else {
        pageData.error = true;
        pageData.finished = true;
        Toast.fail("失败！" + result.data.msg);
      }
    })
    .catch((err) => {
      pageData.loading = false;
      pageData.error = true;
      pageData.finished = true;
      Toast.fail("出错！" + err);
    });
};
/* 刷新页面数据 */
const onRefresh = () => {
  // 清空列表数据
  pageData.list = [];
  pageData.page = 1;
  // pageData.finished = false;
  // 重新加载数据
  onLoad();
};
/* 查看终端 */
const showMac = (item) => {
  if (!item) {
    return false;
  }
  pageData.authMac = [];
  pageData.authMac.push({
    device_type: item.device_type ? item.device_type : "未知",
    mac: item.mac,
  });
  pageData.showMac = true;
};
/* 查看审批原因 */
const showReason = (item) => {
  if (!item) {
    return false;
  }
  pageData.authTime = item.auth_time;
  pageData.authReason = item.refuse_reason;
  pageData.showReason = true;
};
/* 函数初始化-END */
</script>

<style lang="scss">
.warranty-list {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-family: PingFangSC-Regular, PingFang SC;
  .warranty-user {
    width: 100%;
    height: 44px;
    box-sizing: border-box;
    border-style: none;
    border-color: unset;
    font-weight: 400;
    font-size: 16px;
    color: #666666;
    font-style: normal;
    letter-spacing: 0px;
    line-height: 44px;
    text-decoration: none;
    word-break: keep-all;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 12px;
    background: #fafafa;
  }
  .my-warranty-list {
    width: 100%;
    height: calc(100% - 44px);
    background: #ffffff;
    padding: 12px;
    box-sizing: border-box;
    .warranty-list-title {
      height: 46px;
      line-height: 46px;
      font-size: 16px;
      font-weight: 600;
      color: #333333;
      border-bottom: 1px solid #eeeeee;
    }
    .list-info {
      padding-bottom: 20px;
      .van-row {
        border-bottom: 1px solid #eeeeee;
      }
      .van-col {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 85px;
      }
      .list-info-date {
        .date-title {
          font-size: 13px;
          font-weight: 400;
          color: #999999;
          line-height: 24px;
        }
        .date-info {
          font-size: 16px;
          font-weight: 600;
          color: #333333;
          line-height: 28px;
        }
      }
      .list-info-mac {
        .mac-num {
          font-size: 17px;
          font-weight: 400;
          color: #333333;
          line-height: 30px;
        }
        .mac-info {
          font-size: 12px;
          font-weight: 400;
          color: #1677ff;
          line-height: 24px;
        }
      }
      .list-info-status {
        color: #666666;
        .status-info {
          font-size: 16px;
          font-weight: 400;
          line-height: 25px;
        }
        .status-reason {
          font-size: 12px;
          font-weight: 400;
          color: #1677ff;
          line-height: 20px;
        }
      }
    }
  }
  .show-mac {
    background: #ffffff;
    border-radius: 8px;
    .van-dialog__header {
      height: 50px;
      padding: 0 15px;
      line-height: 50px;
      box-sizing: border-box;
      text-align: left;
      border-bottom: 1px solid #eeeeee;
    }
    .close-show-mac {
      position: absolute;
      right: 0;
      top: 0;
      display: inline-block;
      width: 50px;
      height: 50px;
      line-height: 50px;
      text-align: center;
    }
    .auth-mac {
      padding: 0 0 10px 0;
      .van-row {
        padding: 0 15px;
        font-weight: 500;
        font-size: 14px;
        height: 32px;
        color: #666666;
        letter-spacing: 0px;
        line-height: 32px;
        text-decoration: none;
      }
      .van-row:nth-of-type(odd) {
        background: rgba(216, 216, 216, 0);
      }
      .van-row:nth-of-type(even) {
        background: rgba(22, 119, 255, 0.02);
      }
      .van-col {
        font-family: ArialMT;
        color: #333333;
      }
    }
  }
  .show-reason {
    background: #ffffff;
    border-radius: 8px;
    .van-cell {
      padding: 12px;
    }
    .van-cell__title {
      font-size: 16px;
      font-weight: 600;
      color: #666666;
      line-height: 22px;
    }
    .van-cell__value {
      font-size: 14px;
      font-weight: 400;
      color: #1677ff;
      line-height: 22px;
    }
    .van-dialog__confirm {
      width: 196px;
      height: 38px;
      background: #1677ff;
      border-radius: 4px;
      line-height: 38px;
      color: #ffffff;
      font-size: 16px;
    }
    .reject-reason {
      height: 100px;
      border-radius: 4px;
      border: 1px solid #e5e5e5;
      padding: 4px 6px;
    }
    .van-dialog__footer {
      padding: 20px 70px;
    }
  }
}
</style>
