import { commonServerPromise } from "@/utils/http.js";

/**
 * 终端列表
 * @param {*} params
 * @returns Promise {<pending>}
 */
export const getTerminalList = (params) => {
  return commonServerPromise({
    method: "get",
    url: "/query_mac_list",
    params: params,
  });
};

/**
 * 终端质保概况
 * @param {*} params
 * @returns Promise {<pending>}
 */
export const getTerminalWarranty = (params) => {
  return commonServerPromise({
    method: "get",
    url: "/user_mac_index_list",
    params: params,
  });
};

/**
 * 质保终端型号分布
 * @param {*} params
 * @returns Promise {<pending>}
 */
export const getTerminalWarrantyModel = (params) => {
  return commonServerPromise({
    method: "get",
    url: "/user_mac_index_list2",
    params: params,
  });
};

/**
 * 质保到期日期分布
 * @param {*} params
 * @returns Promise {<pending>}
 */
export const getTerminalWarrantyDate = (params) => {
  return commonServerPromise({
    method: "get",
    url: "/user_mac_index_list3",
    params: params,
  });
};

/**
 * 终端质保概况详情
 * @param {*} params
 * @returns Promise {<pending>}
 */
export const getTerminalWarrantyDetails = (params) => {
  return commonServerPromise({
    method: "get",
    url: "/warranty_inquiry",
    params: params,
  });
};

/**
 * 申请延保
 * @param {*} params
 * @returns Promise {<pending>}
 */
export const applyExtendWarranty = (params) => {
  return commonServerPromise({
    method: "post",
    url: "https://inside-info.i-ktv.com/sass/index/approval",
    params: params,
  });
};

/**
 * 我的延保列表
 * @param {*} params
 * @returns Promise {<pending>}
 */
export const warrantyList = (params) => {
  return commonServerPromise({
    method: "get",
    url: "https://inside-info.i-ktv.com//sass/index/approvalList",
    params: params,
  });
};
